<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Summary-RBP"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterKabupatenKota,
} from '@/components/filter'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterDistributor,
        FilterKabupatenKota,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Regional',
                    dataIndex: 'region_toko',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi_toko',
                },
                {
                    title: 'Distrik',
                    dataIndex: 'kabupaten_toko',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'nama_produk',
                },
                {
                    title: 'Principal',
                    dataIndex: 'player',
                },
                {
                    title: 'Kemasan',
                    dataIndex: 'kemasan',
                },
                {
                    title: 'RBP Nett Minggu lalu (W2 AUG)',
                    dataIndex: 'rbp_nett_last_week',
                },
                {
                    title: 'RBP Nett Minggu Ini (W3 AUG)',
                    dataIndex: 'rbp_nett',
                },
                {
                    title: 'Price Movement',
                    dataIndex: 'price_movement',
                },
                {
                    title: 'Keterangan',
                    dataIndex: 'keterangan',
                },
            ],
            endpoint: '/api/report-ats/rbp-movement',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                distributor: [],
                kabupaten: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
        }
    },
})
</script>
